import { useContext } from "react";
import { EverflowContext } from "@/components/Hooks/EverflowProvider";

const useEverflowContext = () => {
    const context = useContext(EverflowContext);
    if (context === undefined)
        throw new Error(
            "useEverflowContext must be used within a EverflowContextProvider"
        );
    return context;
};

export default useEverflowContext;
