import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { AuthUser, PreAuth } from "@/components/types/auth";
import { Affiliate, AffiliateTracking } from "@/components/types/affiliate";
import { Cart, CartItem } from "@/components/types/cart";
import { TimeRange } from "@/components/types/dashboard";
import { PlanAccount, Position, Trade, Notification, Subscription, UserBilling } from "@/components/types/subscription";
import AudioService from "@/components/Hooks/AudioService";

type State = {
    user: AuthUser | null,
    userBilling: UserBilling | null,
    affiliate: Affiliate | null,
    affiliateTracking: AffiliateTracking | null,

    preAuth: PreAuth,
    cart: Cart | null,

    subscriptions: Subscription[] | null,
    planAccounts: PlanAccount[] | null,
    notifications: { [key: string]: Notification[] } | null,
    positions: { [key: string]: Position[] } | null,
    trades: Trade[] | null,

    isLoading: boolean,
    isSignedIn: boolean,
    audioEnabled: boolean,
    audioService: AudioService | null,

    selectedSubscriptionId: string | null,
    selectedSubscription: Subscription | null,
    selectedSubscriptionNotifications: Notification[] | null,

    selectedPlanAccountId: string | null,
    selectedPlanAccount: PlanAccount | null,
    selectedPlanAccountPositions: Position[] | null,

    selectedTimeRange: TimeRange,
}

type Actions = {
    setUser: (user: AuthUser | null) => void,
    getUser: () => AuthUser | null,
    setBilling: (billing: UserBilling | null) => void,
    getBilling: () => UserBilling | null,
    setAffiliate: (affiliate: Affiliate) => void,
    getAffiliate: () => Affiliate | null,
    setAffiliateTracking: (affiliateTracking: AffiliateTracking | null) => void,
    getAffiliateTracking: () => AffiliateTracking | null,
    setCart: (cart: CartItem[] | null) => void,
    getCart: () => Cart | null,
    addCartItem: (cartItem: CartItem) => void,
    removeCartItem: (cartItem: CartItem) => void,
    setEmail: (email: string) => void,
    setIsLoading: (isLoading: boolean) => void,
    setIsSignedIn: (isSignedIn: boolean) => void,
    setAudioEnabled: (audioEnabled: boolean) => void,
    getAudioEnabled: () => boolean,
    setAudioService: (audioService: AudioService | null) => void,
    getAudioService: () => AudioService | null,
    setIsWaitingForCode: (waitingForCode: boolean) => void,
    setIsLinkedCreditCard: (linkedCreditCard: boolean) => void,
    setIsSkipMFA: (skipMFA: boolean) => void,
    resetUserStore: () => void,
    resetCart: () => void,
    setSubscriptions: (subscriptions: Subscription[]) => void,
    getSubscriptions: () => any,
    setSubscription: (subscription: Subscription) => void,
    setPlanAccounts: (planAccounts: PlanAccount[]) => void,
    getPlanAccounts: () => PlanAccount[] | null,
    setNotifications: (notifications: { [key: string]: Notification[] } | null) => void,
    getNotifications: () => { [key: string]: Notification[] } | null,
    setPositions: (positions: { [key: string]: Position[] }) => void,
    getPositions: () => { [key: string]: Position[] } | null,
    setTrades: (trades: Trade[]) => void,
    getTrades: () => Trade[] | null,
    setSelectedSubscriptionId: (subscriptionId: string | null) => void,
    getSelectedSubscriptionId: () => string | null,
    setSelectedSubscription: (subscription: Subscription | null) => void,
    getSelectedSubscription: () => any,
    setSelectedPlanAccountId: (planAccountId: string | null) => void,
    getSelectedPlanAccountId: () => string | null,
    setSelectedPlanAccount: (planAccount: PlanAccount) => void,
    getSelectedPlanAccount: () => PlanAccount | null,
    setSelectedPlanAccountPositions: (positions: Position[] | null) => void,
    getSelectedPlanAccountPositions: () => Position[] | null,
    setSelectedSubscriptionNotifications: (notifications: Notification[] | null) => void,
    getSelectedSubscriptionNotifications: () => Notification[] | null,
    setSelectedTimeRange: (timeRange: TimeRange) => void,
    reset: () => void,
}

const initialState: State = {
    user: null,
    userBilling: null,
    affiliate: null,
    affiliateTracking: null,

    preAuth: {
        email: '',
        waitingForCode: false,
        linkedCreditCard: false,
        skipMFA: false,
    },
    subscriptions: null,
    planAccounts: null,
    notifications: null,
    cart: null,

    isLoading: true,
    isSignedIn: false,
    audioEnabled: true,
    audioService: null,

    positions: {},
    trades: [],

    selectedSubscriptionId: null,
    selectedSubscription: null,
    selectedSubscriptionNotifications: null,
    selectedPlanAccountId: null,
    selectedPlanAccount: null,
    selectedPlanAccountPositions: null,
    selectedTimeRange: TimeRange.ALL,
}

export const useStore = create<State & Actions>()(
    devtools(immer(persist(
        (set, get) => ({
            ...initialState,
            setUser: (user: AuthUser | null) => set(() => ({ user })),
            getUser: () => get().user,
            setAffiliate: (affiliate: Affiliate | null) => set(() => ({ affiliate })),
            getAffiliate: () => get().affiliate,
            setAffiliateTracking: (affiliateTracking: AffiliateTracking | null) => set(() => ({ affiliateTracking })),
            getAffiliateTracking: () => get().affiliateTracking,
            setBilling: (userBilling: UserBilling | null) => set(() => ({ userBilling })),
            getBilling: () => get().userBilling,
            setEmail: (email: string) => set((state) => ({
                preAuth: {
                    ...state.preAuth,
                    email,
                }
            })),
            setIsWaitingForCode: (waitingForCode: boolean) => set((state) => ({
                preAuth: {
                    ...state.preAuth,
                    waitingForCode,
                }
            })),
            setIsLinkedCreditCard: (linkedCreditCard: boolean) => set((state) => ({
                preAuth: {
                    ...state.preAuth,
                    linkedCreditCard,
                }
            })),
            setIsSkipMFA: (skipMFA: boolean) => set((state) => ({
                preAuth: {
                    ...state.preAuth,
                    skipMFA,
                }
            })),
            setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
            setIsSignedIn: (isSignedIn: boolean) => set(() => ({ isSignedIn })),
            setAudioEnabled: (audioEnabled: boolean) => set(() => ({ audioEnabled })),
            getAudioEnabled: () => get().audioEnabled,
            setAudioService: (audioService: AudioService | null) => set(() => ({ audioService })),
            getAudioService: () => get().audioService,
            resetUserStore: () => set(() => ({ user: null })),
            setCart: (cartItems: CartItem[] | null) => set((state) => {
                let total = 0;
                if (cartItems) {
                    cartItems.forEach((item) => {
                        total += item.plan.price;
                    });
                }

                if (state.cart) {
                    // only one of each plan type can be in the cart.
                    // ensure that the cart only has one of each plan type.
                    const cart = state.cart;
                    const currentCartIds = cart?.items?.map((item) => item.plan.id) || [];
                    const newCartIds = cartItems?.map((item) => item.plan.id) || [];
                    const filteredCartIds = currentCartIds.filter((id) => !newCartIds.includes(id));
                    const cartItem = cartItems?.concat(cart.items.filter((item) => filteredCartIds.includes(item.plan.id)));

                    return { cart: { items: cartItem, total } };
                }

                return { cart: { items: cartItems, total } };
            }),
            getCart: () => get().cart,
            addCartItem: (cartItem: CartItem) => set((state) => {
                cartItem.total = cartItem.plan.price;

                if (state.cart) {
                    let total = 0;
                    const cart = state.cart;
                    const currentCartIds = cart.items.map((item) => item?.type?.id);
                    if (!currentCartIds.includes(cartItem?.type?.id)) {
                        const newCartItems = cart.items.concat(cartItem);
                        newCartItems.forEach((item) => {
                            total += item.plan.price;
                        });

                        return { cart: { items: newCartItems, total } };
                    }

                    return { cart };
                }

                return { cart: { items: [cartItem], total: cartItem.plan.price } };
            }),
            removeCartItem: (cartItem: CartItem) => set((state) => {
                if (state.cart) {
                    let total = 0;
                    const cart = state.cart;
                    const newCartItems = cart.items.filter((item) => item?.type?.id !== cartItem?.type?.id);
                    newCartItems.forEach((item) => {
                        total += item.plan.price;
                    });

                    return { cart: { items: newCartItems, total } };
                }

                return { cart: null };
            }),
            resetCart: () => set(() => ({ cart: null })),
            setSubscriptions: (subscriptions: Subscription[]) => set(() => ({ subscriptions })),
            getSubscriptions: () => get().subscriptions,
            setSubscription: (subscription: any) => set((state) => ({
                subscriptions: {
                    ...state.subscriptions,
                    [subscription.id]: subscription,
                }
            })),
            setPlanAccounts: (planAccounts: PlanAccount[]) => set(() => ({ planAccounts })),
            getPlanAccounts: () => get().planAccounts,
            setNotifications: (notifications: { [key: string]: Notification[] } | null) => set(() => ({ notifications })),
            getNotifications: () => get().notifications,
            setPositions: (positions: { [key: string]: Position[] }) => set(() => ({ positions })),
            getPositions: () => get().positions,
            setTrades: (trades: Trade[]) => set(() => ({ trades })),
            getTrades: () => get().trades,
            setSelectedSubscriptionId: (selectedSubscriptionId: string | null) => set(() => ({ selectedSubscriptionId })),
            getSelectedSubscriptionId: () => get().selectedSubscriptionId,
            setSelectedSubscription: (selectedSubscription: Subscription | null) => set(() => ({ selectedSubscription })),
            getSelectedSubscription: () => get().selectedSubscription,
            setSelectedPlanAccountId: (selectedPlanAccountId: string | null) => set(() => ({ selectedPlanAccountId })),
            getSelectedPlanAccountId: () => get().selectedPlanAccountId,
            setSelectedPlanAccount: (selectedPlanAccount: PlanAccount | null) => set(() => ({ selectedPlanAccount })),
            getSelectedPlanAccount: () => get().selectedPlanAccount,
            setSelectedPlanAccountPositions: (selectedPlanAccountPositions: Position[] | null) => set(() => ({ selectedPlanAccountPositions })),
            getSelectedPlanAccountPositions: () => get().selectedPlanAccountPositions,
            setSelectedSubscriptionNotifications: (selectedSubscriptionNotifications: Notification[] | null) => set(() => ({ selectedSubscriptionNotifications })),
            getSelectedSubscriptionNotifications: () => get().selectedSubscriptionNotifications,
            setSelectedTimeRange: (selectedTimeRange: TimeRange) => set(() => ({ selectedTimeRange })),
            reset: () => {
                set(initialState)
            },
        }),
        {
            name: 'vanquish-storage',
            storage: createJSONStorage(() => localStorage),
        },
    ))),
)
