export enum TimeRange {
    TWENTY_FOUR_HOURS = '24h',
    SEVEN_DAYS = '7d',
    THIRTY_DAYS = '30d',
    NINETY_DAYS = '90d',
    ALL = 'all'
}

export const timeRangeOptions = [
    { label: 'Last 24 hours', value: TimeRange.TWENTY_FOUR_HOURS },
    { label: 'Last 7 days', value: TimeRange.SEVEN_DAYS },
    { label: 'Last 30 days', value: TimeRange.THIRTY_DAYS },
    { label: 'Last 90 days', value: TimeRange.NINETY_DAYS },
    { label: 'All time', value: TimeRange.ALL }
];