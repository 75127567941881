'use client';

import React, { useEffect, createContext } from "react";
// @ts-ignore
import EverflowSDK from "@everflow/everflow-sdk";
import { useStore } from "@/components/Hooks/StorageProvider";

interface EverflowContextType {
    SDK: typeof EverflowSDK;
    impression: () => Promise<void>;
    click: () => Promise<void>;
    conversion: () => Promise<void>;
}

export const EverflowContext: React.Context<EverflowContextType> = createContext<EverflowContextType>({
    SDK: EverflowSDK,
    impression: async () => {},
    click: async () => {},
    conversion: async () => {},
});

export const EverflowProvider = ({ children }: { children: React.ReactNode }) => {
    const affiliateTracking = useStore((state) => state.getAffiliateTracking());
    const setAffiliateTracking = useStore((state) => state.setAffiliateTracking);

    const DEFAULT_OFFER_ID = 2;
    const DEFAULT_AFFILIATE_ID = 1;

    useEffect(() => {
        EverflowSDK.configure({
            tracking_domain: 'https://www.vanquishtrader.co',
            organic: {
                offer_id: 2,
                affiliate_id: 1
            },
        });
    }, []);

    const impression = async () => {
        try {
            await EverflowSDK.impression({
                offer_id: EverflowSDK.urlParameter('oid'),
                affiliate_id: EverflowSDK.urlParameter('affid'),
                sub1: EverflowSDK.urlParameter('sub1'),
                sub2: EverflowSDK.urlParameter('sub2'),
                sub3: EverflowSDK.urlParameter('sub3'),
                sub4: EverflowSDK.urlParameter('sub4'),
                sub5: EverflowSDK.urlParameter('sub5'),
                uid: EverflowSDK.urlParameter('uid'),
                source_id: EverflowSDK.urlParameter('source_id'),
                transaction_id: EverflowSDK.urlParameter('_ef_transaction_id'),
            });
        } catch (e) {
            console.error(e);
        }
    }

    const click = async () => {
        try {
            let offerId = EverflowSDK.urlParameter('oid');
            if (!offerId) {
                offerId = DEFAULT_OFFER_ID;
            }

            let affiliateId = EverflowSDK.urlParameter('affid');
            if (!affiliateId) {
                affiliateId = DEFAULT_AFFILIATE_ID;
            }

            // check if the user has already clicked
            if (affiliateTracking?.offerId === offerId && affiliateTracking?.affiliateId === affiliateId) {
                return;
            }

            const tid = await EverflowSDK.click({
                offer_id: offerId,
                affiliate_id: affiliateId,
                sub1: EverflowSDK.urlParameter('sub1'),
                sub2: EverflowSDK.urlParameter('sub2'),
                sub3: EverflowSDK.urlParameter('sub3'),
                sub4: EverflowSDK.urlParameter('sub4'),
                sub5: EverflowSDK.urlParameter('sub5'),
                uid: EverflowSDK.urlParameter('uid'),
                source_id: EverflowSDK.urlParameter('source_id'),
                transaction_id: EverflowSDK.urlParameter('_ef_transaction_id'),
            });

            if (tid) {
                setAffiliateTracking({
                    offerId: offerId,
                    affiliateId: affiliateId,
                    transactionId: tid,
                });
            } else {
                setAffiliateTracking({
                    offerId: offerId,
                    affiliateId: affiliateId,
                    transactionId: null,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    const conversion = async () => {
        try {
            await EverflowSDK.conversion({
                offer_id: EverflowSDK.urlParameter('oid'),
                affiliate_id: EverflowSDK.urlParameter('affid'),
                sub1: EverflowSDK.urlParameter('sub1'),
                sub2: EverflowSDK.urlParameter('sub2'),
                sub3: EverflowSDK.urlParameter('sub3'),
                sub4: EverflowSDK.urlParameter('sub4'),
                sub5: EverflowSDK.urlParameter('sub5'),
                uid: EverflowSDK.urlParameter('uid'),
                source_id: EverflowSDK.urlParameter('source_id'),
                transaction_id: EverflowSDK.urlParameter('_ef_transaction_id'),
                coupon_code: EverflowSDK.urlParameter('coupon_code'),
                event_id: EverflowSDK.urlParameter('event_id'),
                adv_event_id: EverflowSDK.urlParameter('adv_event_id'),
            });
        } catch (e) {
            console.error(e);
        }
    }

    const value = {
        SDK: EverflowSDK,
        impression,
        click,
        conversion,
    };

    return (
        <EverflowContext.Provider value={value}>
            {children}
        </EverflowContext.Provider>
    )
};