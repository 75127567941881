'use client';

import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import useEverflowContext from "@/components/Hooks/useEverflowContext";
import Loading from "@/components/Loading";

const TempPage = () => {
    const [loading, setLoading] = useState(true);
    const { replace } = useRouter();
    const useEverflow = useEverflowContext();

    useEffect(() => {
        const click = async () => {
            useEverflow.click().then(() => {
                setLoading(false);
                replace('/en');
            }).catch((e) => {
                console.error(e);
            });

            setLoading(false);

            // redirect to the homepage
            replace('/en');
        }

        click();
    }, [useEverflow]);

    if (loading) return <Loading />;

    return (
      <Loading />
    );
};

export default TempPage;